import { Button } from "@headlessui/react";
import DRepIDInputField from "@src/components/atoms/inputField";
import CrossIcon from "@src/components/icons/cross";
import {
  IDRepFormField,
  LinkType,
  ReferenceWithId,
} from "@src/models/dtos/metadata";
import { uuid } from "uuidv4";
import { ChangeEvent, useState } from "react";
import { isValidURLFormat } from "@src/utils/validationUtils";
import { IError } from "@src/models/dtos";

export default function LinkFieldManager({
  setDRepRegistrationFormData,
  type,
  links,
  label,
  error,
  setError,
}: {
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField,
  ) => void;
  type: LinkType;
  label: string;
  setError: (value: IError) => void;
  error: IError | undefined;
  links: Array<ReferenceWithId>;
}) {
  const [Links, setLinks] = useState<Array<ReferenceWithId>>(links);

  const updateDRepRegistrationValue = (updatedLink: Array<ReferenceWithId>) => {
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      references: [
        ...prevState.references.filter((ref) => ref["@type"] !== type),
        ...updatedLink,
      ],
    }));
  };

  const updateErrorState = (uuid: string, message?: string) => {
    const currentErrors = Array.isArray(error?.errors) ? error.errors : [];

    const updatedErrors = message
      ? [
          ...currentErrors.filter((err) => err.id !== uuid),
          { id: uuid, message },
        ]
      : currentErrors.filter((err) => err.id !== uuid);

    setError({
      step: type === "Link" ? 3 : 4,
      errors: updatedErrors,
    });
  };
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, uuid: string) => {
    const { id, value } = e.target;
    const updatedLink = Links.map((socialMediaLink: ReferenceWithId) => {
      if (socialMediaLink.id === uuid) {
        if (id === "uri" && !isValidURLFormat(value)) {
          updateErrorState(uuid, "Invalid URL.");
        } else {
          updateErrorState(uuid);
        }

        return {
          ...socialMediaLink,
          [id]: value,
        };
      }
      return socialMediaLink;
    });

    setLinks(updatedLink);
    updateDRepRegistrationValue(updatedLink);
  };

  const handleAddLink = () => {
    const updatedLink: ReferenceWithId[] = [
      ...Links,
      {
        id: uuid(),
        "@type": type,
        label: "",
        uri: "",
      },
    ];
    setLinks(updatedLink);
  };

  const handleOnDelete = (id: string) => {
    const updatedLink = Links.filter((link) => link.id !== id);
    setLinks(updatedLink);
    updateDRepRegistrationValue(updatedLink);
  };

  const handleErrorMessage = (identity: string) => {
    if (error && error.errors.length !== 0) {
      const filterCurrentError = error.errors.filter(
        (error) => error.id === identity,
      );
      if (filterCurrentError.length !== 0) {
        return filterCurrentError[0].message;
      }
    }
    return "";
  };

  return (
    <div className="flex flex-col  gap-6">
      <div className="flex flex-col gap-3">
        <p className="text-neutral-600 body2 font-semibold">{label}</p>
        <div className="flex flex-col gap-10">
          {Links.map((link) => {
            return (
              <div className="flex flex-col gap-3 relative">
                {Links.length > 1 && (
                  <CrossIcon
                    onClick={() => handleOnDelete(link.id)}
                    className="absolute cursor-pointer h-5 w-5 right-0 -top-2"
                  />
                )}
                <DRepIDInputField
                  id="label"
                  label="Description"
                  value={link.label}
                  onChange={(e) => handleOnChange(e, link.id)}
                  labelClassName="!body3 !font-normal"
                  placeholder={
                    type === "Link"
                      ? "eg: LinkedIn profile"
                      : "eg : Personal website"
                  }
                />
                <DRepIDInputField
                  id="uri"
                  value={link.uri}
                  error={handleErrorMessage(link.id)}
                  labelClassName="!body3 !font-normal"
                  label="URL"
                  onChange={(e) => handleOnChange(e, link.id)}
                  placeholder="Enter URL"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center">
        {Links.length < 8 && (
          <Button onClick={handleAddLink} className="btn-ghost">
            + Add link
          </Button>
        )}
      </div>
    </div>
  );
}
