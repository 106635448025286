import DRepIDInputField from "@src/components/atoms/inputField";
import { IError } from "@src/models/dtos";
import { IDRepFormField } from "@src/models/dtos/metadata";
import { isValidReceivingAddress } from "@src/utils/validationUtils";
import { uuid } from "uuidv4";

export default function StepSecond({
  setDRepRegistrationFormData,
  dRepRegistrationFormData,
  setError,
  error,
}: {
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField,
  ) => void;
  setError: (value: IError | undefined) => void;
  dRepRegistrationFormData: IDRepFormField;
  error: IError | undefined;
}) {
  return (
    <DRepIDInputField
      label="Payment address"
      value={dRepRegistrationFormData.paymentAddress}
      placeholder="Enter your payment address"
      error={(error?.step === 2 && error?.errors?.[0]?.message) || ""}
      onChange={(e) => {
        if (!isValidReceivingAddress(e.target.value)) {
          setError({
            step: 2,
            errors: [
              {
                id: uuid(),
                message: "Invalid payment address.",
              },
            ],
          });
        } else {
          setError(undefined);
        }
        setDRepRegistrationFormData((prevState: IDRepFormField) => ({
          ...prevState,
          paymentAddress: e.target.value,
        }));
      }}
    />
  );
}
