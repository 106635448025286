import { InputHTMLAttributes } from "react";
import cn from "classnames";
import { isEmpty } from "lodash";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  className?: string;
  labelClassName?: string;
  error?: string;
}
export default function DRepIDInputField({
  label,
  className,
  labelClassName,
  error,
  ...props
}: InputFieldProps) {
  return (
    <label className="flex flex-col gap-2">
      <span
        className={cn("body2 font-semibold text-neutral-600", labelClassName)}
      >
        {label}
      </span>
      <input
        {...props}
        className={cn(
          "h-[62px] w-full body2 rounded-[12px] border focus:outline-0  focus:border-neutral-400 border-neutral-300 placeholder:text-neutral-400 text-neutral-800 px-6 py-4",
          className,
          !!error &&
            !isEmpty(error) &&
            "border-error-700 focus:border-error-700",
        )}
      />
      {!!error && !isEmpty(error) && (
        <span className="text-error-700 tiny-text">{error}</span>
      )}
    </label>
  );
}
