import CrossIcon from "@src/components/icons/cross";
import { useModal } from "../context";
import { Button } from "@headlessui/react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { RootState } from "@src/store/store";
import { getCip30Wallet } from "@src/utils/kuberUtils";
import { dRepDeRegistration } from "@src/lib/kuber-service";
import { blake2bHash } from "@src/utils/stringUtils";
import {
  selectPendingTransactions,
  setPendingTransactions,
} from "@src/store/transaction/transaction";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";

export default function RetireDRepModal({ tokenName }: { tokenName: string }) {
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const wallet = useAppSelector((state: RootState) => state.wallet);
  const dispatch = useAppDispatch();
  const pendingTransactions = useAppSelector(selectPendingTransactions);

  const handleDRepDeRegister = async () => {
    setIsLoading(true);
    if (wallet && wallet.instance) {
      try {
        const kuberClientWallet = await getCip30Wallet(wallet);
        const dRepPubKey =
          (await kuberClientWallet.instance.cip95?.getPubDRepKey()) || "";
        const stakeDelegationResponse = await dRepDeRegistration(
          kuberClientWallet,
          "22" + blake2bHash(dRepPubKey),
        );

        const signTx = await kuberClientWallet.getSignedTx(
          stakeDelegationResponse.cborHex,
        );

        await kuberClientWallet.submitTx(signTx).then((txId) => {
          if (txId) {
            dispatch(
              setPendingTransactions({
                ...pendingTransactions,
                pendingDRepRetireTransaction: {
                  txId: txId as string,
                  name: tokenName,
                  time: Date.now(),
                },
              }),
            );
          }
        });
      } catch (e: any) {
        toast.error(`Failed to de-register dRep due to ${e.message}`, {
          autoClose: false,
          toastId: ToastId.ERROR_TOAST,
        });
      } finally {
        setIsLoading(false);
        closeModal();
      }
    }
  };
  return (
    <div className="flex flex-col py-10 px-12 w-full bg-white relative shadow-modal-shadow rounded-[16px] max-w-[762px]">
      <CrossIcon
        onClick={closeModal}
        className="h-10 w-10 cursor-pointer  text-neutral-400 absolute top-[28px] right-[24px]"
      />
      <p className="h3 font-bold text-neutral-900">Retire as a DRep?</p>
      <p className="h4 !font-normal text-neutral-900 flex flex-col gap-10 mt-8 mb-12">
        <span>
          By retiring, you give up your voting rights. Voting power delegated to
          you will remain in place.
        </span>
        <span>
          Delegators will still see your status as retired in the DRep directory
          and can re-delegate their votes to another DRep.
        </span>
        <span>
          You can re-enter by delegating your personal voting power to another
          DRep or by coming out of retirement and resuming your previous role.
        </span>
        <span>
          If you choose to come out of retirement, your DRep ID and voting power
          will revert to the state before retirement, including your Ada balance
          and any delegated power.
        </span>
      </p>
      <div className="flex gap-6 justify-end items-center">
        <Button onClick={closeModal} className="btn-ghost">
          Cancel
        </Button>
        <Button
          onClick={handleDRepDeRegister}
          disabled={isLoading}
          className="btn-primary"
        >
          Retire
        </Button>
      </div>
    </div>
  );
}
